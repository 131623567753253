import {
    ChangeDetectionStrategy,
    Component,
    computed, input,
    OnChanges,
    OnDestroy,
    OnInit,
    signal,
    SimpleChanges, viewChild,
} from '@angular/core';
import {Domain} from "../../services/for-sale.service";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from "@angular/material/table";
import {of, Subscription, timer} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {DomainService} from "../../services/domain.service";
import {UtilService} from "../../services/util.service";
import {BusyService} from "../../services/busy.service";
import {StateService} from "../../services/state.service";
import {CacheService} from "../../services/cache.service";
import {NotificationService} from "../../services/notification.service";
import {AlertService} from "../../services/alert.service";
import {finalize, mergeMap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "../../util/constants";
import {DnsZoneService} from "../../services/dns-zone.service";
import {CacheMessageMapper} from "../../util/cache-message-mapper";
import {NgClass} from '@angular/common';
import {MatOption} from '@angular/material/core';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';

const PRIMARY_DNS_GROUP_NAME = "dpns";
const MIN_SEARCH_LENGTH = 3;

@Component({
    selector: 'app-dns-zone',
    templateUrl: './dns-zone.component.html',
    styleUrls: ['./dns-zone.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormField, MatInput, ReactiveFormsModule, MatAutocompleteTrigger, FormsModule, MatProgressSpinner, MatIconButton, MatSuffix, MatIcon, MatAutocomplete, MatOption, MatButton, NgClass, MatLabel, MatPaginator, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class DnsZoneComponent implements OnInit, OnDestroy, OnChanges{
    zone = input.required<string>();

    readonly columnsToDisplay = ['domain', 'inRegistry', 'actions'];

    domains = signal<Domain[]>([]);
    newDomain = signal('');
    domainFilter = signal('');
    filteredMatches = signal<string[]>([]);
    searching = signal(false);
    dataSource = signal(new MatTableDataSource<Domain>([]));

    private prefix: string = "";
    private allMatches: string[] = [];
    private filterPrefix: string = "";
    private notifSub?: Subscription;
    protected readonly isAdmin: boolean;

    paginator = viewChild<MatPaginator>('paginator');


    hasDomain = computed(() =>
        !!this.newDomain() && Constants.DOMAIN_REGEX.test(this.newDomain())
    );
    hasDomains = computed(() => this.domains().length > 0);
    isBusy = computed(() => this.busyService.isBusy());


    constructor(private dnsZoneService: DnsZoneService,
                private domainService: DomainService,
                private utilService: UtilService,
                private busyService: BusyService,
                private state: StateService,
                private cache: CacheService,
                private notificationService: NotificationService,
                private alertService: AlertService) {
        const userRole = this.state.get('userRole')();
        this.isAdmin = ["Admin", "Super Admin"].includes(userRole);
    }

    ngOnInit() {
        // this.init();
    }

    ngOnDestroy() {
        this.cleanup();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("DnsZone changes:", changes);
        this.cleanup();
        this.init();
    }

    private init() {
        this.clearSearch();
        this.filterCleared();
        // Connect to push notification service if not yet connected (e.g. if page reloaded)
        this.notificationService.connect();
        // Load all domains for sale
        this.loadDomains();
        // Update domain list when server tells us it has changed
        this.notifSub = this.notificationService.message$.subscribe((message) => {
            const info = CacheMessageMapper.map(message, CacheMessageMapper.DNS_ZONE_PAT);
            if (info) {
                const zone = info.label;
                console.log(`dns-zone notification received for zone ${zone} - clearing cache...`);
                this.cache.clear(...info.tags);
                if (zone == this.zone()) {
                    console.log(`dns-zone notification received for current zone ${zone} - reloading domains...`);
                    this.domains.set([]);
                    this.loadDomains();
                }
            }
        });
    }

    private cleanup() {
        this.notifSub?.unsubscribe();
    }

    private loadDomains() {
        const tag = `dns-zone-domains:${this.zone()}`;
        if (this.cache.has(tag)) {
            this.domains.set(this.cache.get(tag));
            timer(0).subscribe(() => {this.updateDataSource()})
            return;
        }
        this.showBusy(true);
        this.dnsZoneService.getAllDomains(this.zone()).pipe(
            finalize(() => { this.showBusy(false) })
        ).subscribe((domains) => {
            this.domains.set(domains);
            this.cache.set(tag, domains);
            timer(0).subscribe(() => {this.updateDataSource()})
        })
    }

    private updateMatches(value: string) {
        this.prefix = value;
        this.filterPrefix = "";
        this.searching.set(true);
        this.domainService.find(value).pipe(
            finalize(() => {this.searching.set(false)})
        ).subscribe({
                next: (matches) => {
                    this.allMatches = matches;
                    this.updateFilteredMatches();
                },
                error: (err: HttpErrorResponse) => {
                    console.error(err);
                    this.alertService.error("Error finding domain matches: " + err.message);
                }
            }
        )
    }

    private updateFilteredMatches() {
        this.filteredMatches.set(this.allMatches.filter((m) => m.includes(this.filterPrefix)));
        console.log(`Filter - prefix: ${this.filterPrefix} - matches: ${this.filteredMatches().length}`);
    }

    handleDomainInputChange() {
        const value: string = this.newDomain();
        const validDomain: boolean = Constants.DOMAIN_REGEX.test(value);
        if (!this.prefix && value.length >= MIN_SEARCH_LENGTH && !validDomain)
            this.updateMatches(value);
            // If input value is just a longer string with the original prefix: just filter the options from stored
        // array dynamically
        else if (this.prefix && value.length > this.prefix.length && value.startsWith(this.prefix)) {
            this.filterPrefix = value;
            this.updateFilteredMatches();
            // If input value is no longer an extension of prefix: if shorter than min length, just clear all matches
            // otherwise set it as the new prefix and update matches
        } else if (!value.startsWith(this.prefix)) {
            if (value.length < MIN_SEARCH_LENGTH) {
                this.allMatches = [];
                this.prefix = this.filterPrefix = "";
                this.updateFilteredMatches()
            } else if (!validDomain)
                this.updateMatches(value);
        }
    }

    clearSearch() {
        this.newDomain.set('');
        this.handleDomainInputChange();
    }

    private showBusy(flag: boolean) {
        if (flag)
            this.busyService.showBusy();
        else
            this.busyService.showNotBusy();
    }

    private updateDataSource() {
        if (!this.paginator())
            this.dataSource.set(new MatTableDataSource<Domain>([]));
        else {
            let source = new MatTableDataSource<Domain>(this.domains());
            source.filter = this.domainFilter();
            source.paginator = this.paginator() || null;
            this.dataSource.set(source);
        }
    }

    filterChanged() {
        this.domainFilter.update(filter => filter.trim().toLowerCase());
        this.updateDataSource();
        this.dataSource().paginator?.firstPage();
    }

    filterCleared() {
        this.domainFilter.set('');
        this.updateDataSource();
        this.dataSource().paginator?.firstPage();
    }

    maybeLink(domain: Domain): string {
        return domain.inRegistry? `<a href="/domains/${domain.name}">${domain.name}</a>` : domain.name;
    }

    maybeAddDomain() {
        if (this.hasDomain())
            this.addDomain();
    }

    addDomain() {
        const domain = this.newDomain().toLowerCase();
        // Validate new domain to add
        if (!domain.match(Constants.DOMAIN_REGEX))
            this.alertService.error(`Not a valid domain name: <b>${domain}</b>`);
        else if (this.domains().find(d => d.name == domain))
            this.alertService.error(`Domain <b>${domain}</b> already for sale`);
        else {
            // Assume we own the domain and add it to for-sale list
            this.showBusy(true);
            this.dnsZoneService.addDomain(this.zone(), domain).pipe(
                finalize(() => {
                    this.showBusy(false);
                })
            ).subscribe({
                next: (info) => {
                    console.log("addDomain got info:", info);
                    if (!info.usesPrimaryDns) {
                        // It is registered with April Sea but with wrong DNS server group: change it!
                        // First get nameserver group list and find the primary DNS group in it.
                        // Then queue this domain to change its nameservers to primary group
                        this.showBusy(true);
                        this.domainService.getNameServerGroups().pipe(
                            mergeMap((groups) => {
                                const primaryGroup = groups.find((g) => g.name == PRIMARY_DNS_GROUP_NAME);
                                if (primaryGroup)
                                    return this.domainService.changeNameServerGroup(domain, primaryGroup.name);
                                else
                                    return of(null);
                            }),
                            finalize(() => {
                                this.showBusy(false);
                            })
                        ).subscribe({
                            next: (out) => {
                                this.domainAddSuccess(domain, true);
                            },
                            error: this.utilService.getErrorHandler("Error changing DNS server group")
                        });
                    } else
                        this.domainAddSuccess(domain);

                },
                error: this.utilService.getErrorHandler("Error adding domain")
            });
        }
    }

    private domainAddSuccess(domain: string, dnsUpdated: boolean = false, dnsUpdateNeeded: boolean = false) {
        this.newDomain.set('');
        let msg: string = `Domain ${domain} successfully added.`;
        if (dnsUpdated)
            msg += "<br/>DNS server settings had to be updated to use primary servers.";
        if (dnsUpdateNeeded)
            msg += "Note that this won't take effect until after you change DNS settings via external registrar.";
        else
            msg += "<br/>Note it may take a few minutes for this change to become active.";
        this.alertService.info(msg);
        // this.loadDomains();
    }

    removeDomain(domain: string) {
        this.alertService.confirm(`Are you sure you want to delete domain <b>${domain}</b> from for-sale list?`)
            .subscribe((answer) => {
                if (answer) {
                    this.showBusy(true);
                    this.dnsZoneService.removeDomain(this.zone(), domain).pipe(
                        finalize(() => {
                            this.showBusy(false);
                        })
                    ).subscribe({
                        next: (out) => {
                            this.alertService.info(`Domain ${domain} successfully deleted`);
                        },
                        error: this.utilService.getErrorHandler("Error deleting domain")
                    });
                }
            });
    }

}
