import {computed, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {OkInfo, StringMap} from "../util/interfaces";
import {from, Observable} from "rxjs";
import {PathService} from "./path.service";
import {map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {StateService} from "./state.service";

export interface User {
    id: number;
    email: string;
}

export interface Session {
    token: string;
    userId: number;
    userRole: string;
    idle: number;
    trustToken?: string;
}

interface Forgotten {
    forgotten: number;
}

export interface TrustedDevice {
    id: number;
    device_info: string;
    host: string;
    updated: number;
    alias: string;
}

interface Devices {
    devices: TrustedDevice[];
}

export interface TrustedForgotten {
    thisDevice: boolean;
}

const TRUST_TOKEN_KEY = "dom-trust-token";


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly authRoot: string;

    constructor(private http: HttpClient,
                private router: Router,
                private state: StateService,
                private pathService: PathService) {
        this.authRoot = `${this.pathService.apiRoot}/auth`;
    }

    private getTrustTokens(): StringMap {
        let tokenInfo: string|null = localStorage.getItem(TRUST_TOKEN_KEY);
        if (!tokenInfo)
            return {};
        let tokens = {};
        try {
            tokens = JSON.parse(tokenInfo);
            return tokens.constructor.name == "Object"? tokens : {};
        } catch (e) {
            return {};
        }
    }

    private getTrustToken(forEmail?: string): string | undefined {
        if (!forEmail)
            forEmail = this.state.get('email')();
        if (!forEmail)
            return undefined;
        const tokens: StringMap = this.getTrustTokens();
        return tokens[forEmail];
    }

    hasTrustToken(forEmail?: string): boolean {
        return !!this.getTrustToken(forEmail);
    }

    private removeTrustToken(forEmail?: string) {
        if (!forEmail)
            forEmail = this.state.get('email')();
        if (!forEmail)
            return;
        let tokens: StringMap = this.getTrustTokens();
        delete tokens[forEmail];
        localStorage.setItem(TRUST_TOKEN_KEY, JSON.stringify(tokens));
    }

    private setTrustToken(token: string, forEmail?: string) {
        if (!forEmail)
            forEmail = this.state.get('email')();
        if (!forEmail)
            return;
        let tokens: StringMap = this.getTrustTokens();
        tokens[forEmail] = token;
        localStorage.setItem(TRUST_TOKEN_KEY, JSON.stringify(tokens));
    }

    logIn(email: string, password: string, mfaCode?: string, trustThisDevice: boolean=false, pwa: boolean|null=null): Observable<Session> {
        if (mfaCode)
            this.removeTrustToken(email);
        const trustToken = this.getTrustToken(email);
        const body = {
            email: email,
            password: password,
            mfaCode: mfaCode || null,
            trustToken: trustToken || null,
            trustThisDevice: trustThisDevice,
            pwa: pwa
        };
        return this.http.post<Session>(`${this.authRoot}/login`, body).pipe(
            tap({
                next: (session) =>  {
                    this.state.setMany({token: session.token, userId: session.userId, idle: session.idle, userRole: session.userRole});
                    if (session.trustToken)
                        this.setTrustToken(session.trustToken, email);
                }, error: (err: HttpErrorResponse) => {
                    if (err.status == 401 && err.error?.message == "Not a trusted device")
                        this.removeTrustToken(email);
                }
            })
        );
    }

    logOut(): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.authRoot}/logout`, {});
    }

    forgetTrustedDevices(): Observable<number> {
        return this.http.get<Forgotten>(`${this.authRoot}/forget_trusted`).pipe(
            map((f) => f.forgotten),
            tap(() => {
                this.removeTrustToken();
            })
        );
    }

    getTrustedDevices(): Observable<TrustedDevice[]> {
        return this.http.get<Devices>(`${this.authRoot}/trusted`).pipe(
            map((d) => d.devices || [] )
        );
    }

    forgetTrustedDevice(dev: TrustedDevice): Observable<boolean> {
        return this.http.delete<TrustedForgotten>(`${this.authRoot}/trusted/${dev.id}`, {
           body: { trustToken: this.getTrustToken() }
        }).pipe(
            map((tf) => {
                // If we're told that the device just forgotten was this one, then remove trust token from local storage
                if (tf.thisDevice)
                    this.removeTrustToken();
                return tf.thisDevice;
            })
        );
    }

    updateTrustedDevice(dev: TrustedDevice): Observable<OkInfo> {
        return this.http.put<OkInfo>(`${this.authRoot}/trusted/${dev.id}`, { alias: dev.alias});
    }

    isLoggedIn = computed(() => !!this.state.get<string>('token')());

    isSuperAdmin = computed(() => this.state.get('userRole')() == "Super Admin");

    /** Redirects user to login page, remembering what target URL the user really wanted to reach, so they get sent
     * to it after login, if successful
     *
     * @param targetUrl (optional) where to go after login
     */
    redirectToLogIn(targetUrl?:string) {
        from(this.router.navigateByUrl('/login')).subscribe(() => {
            this.state.set('targetUrl', targetUrl);
        });
    }

    /** Requests that an email be sent to the given address with a link to a page where the user can set a new password.
     * It fails if the email address does not correspond to a valid user.
     *
     * @param userEmail the user's email
     */
    requestPasswordReset(userEmail: string): Observable<OkInfo> {
        return this.http.post<OkInfo>(`${this.authRoot}/forgot_password`, {email: userEmail});
    }

    /** Changes the password for the user linked to the given reset token to the new given password string
     *
     * @param userEmail the user's email
     * @param token reset token received from system to validate this action
     * @param newPassword the new desired password
     */
    resetPassword(userEmail: string, token: string, newPassword: string): Observable<any> {
        return this.http.put<OkInfo>(`${this.authRoot}/reset_password`,
                                {email: userEmail, token: token, password: newPassword});
    }

}
